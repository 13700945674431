import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./game.css";
import { mainGame, updateInventory } from "./gameMain";
import { loadTextures } from "./loadTextures";
import * as PixiRenderer from "./viewport";
import * as PIXI from "pixi.js";
import planteur from "../../assets/jauges/berry.png";
import planteurEmpty from "../../assets/jauges/berry_empty.png";
import arroseur from "../../assets/jauges/water.png";
import arroseurEmpty from "../../assets/jauges/water_empty.png";
import fertilizer from "../../assets/jauges/fertilizer.png";
import fertilizerEmpty from "../../assets/jauges/fertilizer_empty.png";
import CO2contour from "../../assets/HUD/CO2_contour.png";
import CO2interieur from "../../assets/HUD/CO2_interieur.png";
import gif_UI from "../../assets/ui/player_action.gif";

import fertiliseurIco from "../../assets/Fertilizer_ico.png";
import laboureurIco from "../../assets/Laboureur_ico.png";
import planteurIco from "../../assets/Planteur_ico.png";
import arroseurIco from "../../assets/Waterer_ico.png";

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

// import 'react-toastify/dist/ReactToastify.css';


// var div = document.createElement("div");
// div.className = "notification";
// div.innerHTML =
//   '<div class="notification-img"> <img class="notification-ico" src="' +
//   image +
//   '"/> </div> <div class="notification-text">' +
//   text +
//   "</div>";


// const Notification = props => {
//   const [visible, setVisible] = useState(true);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setVisible(false);
//     }, props.delay);
//     return () => clearTimeout(timer)
//   }, [props.delay]);

//   return visible ? <div>{props.children}</div> : <div />;
// };

// export default Notification;

function Game({ user }) {
	let inventory;
	const ref = useRef(null);
	const [itemsHUD, setItemsHUD] = useState(<></>);
	const [isLoaded, setIsLoaded] = useState(false);
	const [playerInfo, setPlayerInfo] = useState(null);
	const [itemsHUDRender, setItemsHUDRender] = useState(false);
	const [co2Level, setCo2Level] = useState(100);
	const [notificationsToggle, setNotificationsToggle] = useState(true);
	const navigate = useNavigate();

	// const [notifications, setNotifications] = useState([]);


	function getImage({ type }) {
		switch (type) {
			case "laboureur":
				return laboureurIco;
			case "planteur":
				return planteurIco;
			case "arroseur":
				return arroseurIco;
			case "fertilizer":
				return fertiliseurIco;
			default:
				return laboureurIco;
		}
	}
	function getText({ name, type, tile }) {
		if (type == "laboureur" && tile == "dry") {
			return `${name} plowed the ground`;
		}
		if (type == "planteur" && tile == "plowed") {
			return `${name} planted a seed`;
		}
		if (type == "planteur" && tile == "berry") {
			return `${name} picked berries`;
		}
		if (type == "arroseur" && tile == "seeded") {
			return `${name} watered a seed`;
		}
		if (type == "arroseur" && tile == "water") {
			return `${name} picked water`;
		}
		if (type == "fertilizer" && tile == "watered") {
			return `${name} fertilized a seed`;
		}
		if (type == "fertilizer" && tile == "fertilizer") {
			return `${name} picked fertilizer`;
		}
		return null;
	}



	useEffect(() => {
		if (isLoaded == true) {
			let player = [];
			let renderer = PixiRenderer.start();
			const loader = new PIXI.Loader();
			loadTextures({ loader });
			loader.load((_, resources) => {
				player = mainGame({
					viewport: PixiRenderer.getViewport(),
					resources,
					user,
					document,
					onPlayerChange: ({ player, co2 }) => {
						let tmp = { ...player };
						if (co2 <= 1) {
							navigate("/end");
						}
						setCo2Level(co2);
						setPlayerInfo(tmp);
					},
					onEdit: (data) => {
						console.log("RECU DANS REACT", data.name, data.type, data.tile);
						const text = getText(data);
						if (text) {
							const image = getImage(data);
							console.log("TEXTNOTIF", text);
							console.log("INNER", window.innerWidth);
							if (window.innerWidth > 900) {
								toast(text, {
									closeButton: false, icon: ({ theme, type }) =>
										<img alt="player ico" src={image} style={{ imageRendering: "pixelated", width: "40px", height: "40px" }} />
								});
							}
						}
					},
				});
				setPlayerInfo(player);
			});
			ref.current.appendChild(renderer.view);
		}
	}, [isLoaded]);

	useEffect(() => {
		if (isLoaded == false) {
			setIsLoaded(true);
		}
		return () => {
			// viewApp.getViewport().destroy();
			// renderer.destroy(true,true);
			// viewApp.get().destroy(true,true);
		};
	}, []);

	useEffect(() => {
		if (playerInfo != null) {
			let contentItems = [false, false, false, false, false];
			for (let i = 0; i < playerInfo.capacity; i++) {
				contentItems[i] = true;
			}
			if (playerInfo.type != "laboureur") {
				setItemsHUDRender(true);
			} else {
				setItemsHUDRender(false);
			}
			const listOfItemsHUD = contentItems.map((value, index) => {
				if (playerInfo.type === "planteur" && value)
					return (
						<div key={"inventory" + index}>
							<img src={planteur} />
						</div>
					);
				else if (playerInfo.type === "planteur")
					return (
						<div key={"inventory" + index}>
							<img src={planteurEmpty} />
						</div>
					);
				else if (playerInfo.type === "arroseur" && value)
					return (
						<div key={"inventory" + index}>
							<img src={arroseur} />
						</div>
					);
				else if (playerInfo.type === "arroseur")
					return (
						<div key={"inventory" + index}>
							<img src={arroseurEmpty} />
						</div>
					);
				else if (playerInfo.type === "fertilizer" && value)
					return (
						<div key={"inventory" + index}>
							<img src={fertilizer} />
						</div>
					);
				else
					return (
						<div key={"inventory" + index}>
							<img src={fertilizerEmpty} />
						</div>
					);
			});
			setItemsHUD(listOfItemsHUD);
		}
	}, [playerInfo]);

	return (
		<>
			{
				notificationsToggle ?
					<ToastContainer
						position="top-right"
						autoClose={1500}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
						theme="dark"
					/> : <></>
			}
			<button className="hud" id="toggle-notifications" onClick={() => { setNotificationsToggle(!notificationsToggle) }}>Notifications {notificationsToggle ? "On" : "Off"}</button>
			<div className="game-container" ref={ref}>
				<div className="hud" id="gif_ui">
					<img src={gif_UI} />
				</div>
				{itemsHUDRender ? (
					<div className="hud" id="inventory">
						{itemsHUD}{" "}
					</div>
				) : null}
				<div className="hud" id="co2">
					<div id="relativeCO2">
						<img
							id="interieur_CO2"
							src={CO2interieur}
							style={{
								height: co2Level + "%",
								paddingBottom: parseInt(((100 - co2Level) * 5) / 100) + "px",
							}}
						/>
						<img src={CO2contour} />
					</div>
				</div>
			</div>
		</>
	);
}

export default Game;
