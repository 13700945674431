import React, { useEffect, useState } from "react";
import './style.css'
import { useNavigate } from "react-router-dom";

function Login({ setUser, user }) {
  const history = useNavigate();
  const handleLogin = ({uuid, login}) => {
    // setUser({ uuid: "1", login: "juthomas" });
    setUser({ uuid, login });
    history("/game");
  };
  const handleLogout = () => setUser(null);

  function login(e) {
    e.preventDefault();
    console.log("Event")
    const username = Object.fromEntries(new FormData(e.target))["username"];

    if (username.length > 0) {
      document.getElementById("cl").classList.toggle("cloudLeft-active");
      document.getElementById("cr").classList.toggle("cloudRight-active");
      document.getElementById("login").classList.toggle("loginClass-active");
      console.log("Clicked");

      //Faire une requette au serveur ici pour chopper un UUID si on entre un login
      handleLogin({uuid : 42, login:username});

    } else {
      // document.getElementById("Username").classList.add("error");
      handleLogout();
    }
  }

  return (
    <div id="login" className="div_login loginClass transformSlow">
      <div id="cl" className="div_login transform cloudLeft"></div>
      <div id="cr" className="div_login transform cloudRight"></div>
      <div className="div_login inputs transform">
        <h1 id="title_login">One For Tree !</h1>
        <form className="connection-form" id="connectionForm" onSubmit={login}>
          <input className="input_login" type="text" name="username" placeholder="Username" />
          <input className="input_login" type="submit" value="PLAY !" />
        </form>
      </div>
    </div>
  );
}

export default Login;
