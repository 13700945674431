import "./App.css";
import Game from "./pages/game";
import {
  BrowserRouter,
  // useRoutes,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import Login from "./pages/login";
import End from "./pages/end";
import { useEffect, useState } from "react";

const ProtectedRoute = ({ user, redirectPath = "/login" }) => {
  console.log("user :", user);
  //user verification (normaly with the server)
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

function App() {
  const [user, setUser] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("onefortree_user");
    const initialValue = JSON.parse(saved);
    return initialValue || null;
  });

  useEffect(() => {
    localStorage.setItem("onefortree_user", JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    console.log("OUI OUI OUI OUI OUI OUI");
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<Navigate to="game" />} />
          <Route
            path="login"
            element={<Login setUser={setUser} user={user} />}
          />
          <Route element={<ProtectedRoute user={user} />}>
            <Route path="game" element={<Game user={user} />} />
          </Route>
          <Route path="end" element={<End/>} />
          <Route path="*" element={<Navigate to="game" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
